<template>
  <div class="card">
    <div class="card-body">
      <h5 class="font-weight-bolder">Meta Information</h5>
      <!-- Items -->
      <div class="mb-3">
        <h6>Items</h6>
        <div class="row g-2">
          <div v-for="(value, key) in meta.items" :key="key" class="col-md-4">
            <label class="form-label">{{
              key.charAt(0).toUpperCase() + key.slice(1)
            }}</label>
            <input
              v-model.number="meta.items[key]"
              type="number"
              class="form-control"
              @input="emitChanges"
            />
          </div>
        </div>
      </div>
      <!-- Category -->
      <div class="mb-3">
        <h6>Category</h6>
        <argon-select
          id="category-input"
          :multiple="true"
          :model-value="meta.category"
          placeholder="Category"
          :options="{
            choices: categoryChoices,
            searchEnabled: false,
          }"
          @update:model-value="updateCategories"
        ></argon-select>
      </div>
      <!-- Ribbon Tag -->
      <div class="mb-3">
        <h6>Ribbon Tag</h6>
        <div class="row g-2">
          <div class="col-md-6">
            <label class="form-label">English Text</label>
            <input
              v-model="meta.ribbon_tag.text.en"
              type="text"
              class="form-control"
              @input="emitChanges"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label">Arabic Text</label>
            <input
              v-model="meta.ribbon_tag.text.ar"
              type="text"
              class="form-control"
              dir="rtl"
              @input="emitChanges"
            />
          </div>
          <div class="col-md-12">
            <label class="form-label">Color Key</label>
            <select
              v-model="meta.ribbon_tag.color_key"
              class="form-control"
              @change="emitChanges"
            >
              <option value="">Select a color</option>
              <option v-for="key in colorKeys" :key="key" :value="key">
                {{ key }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- Suitable For -->
      <div class="mb-3">
        <h6>Suitable For</h6>
        <label class="form-label">English</label>
        <input id="suitable-for-input-en" type="text" class="form-control" />
        <label class="form-label mt-2">Arabic</label>
        <input
          id="suitable-for-input-ar"
          type="text"
          class="form-control"
          dir="rtl"
        />
      </div>
      <!-- Available Sizes -->
      <div class="mb-3">
        <h6>Available Sizes</h6>
        <div v-for="(size, sizeKey) in meta.available_sizes" :key="sizeKey">
          <h6>{{ sizeKey.charAt(0).toUpperCase() + sizeKey.slice(1) }}</h6>
          <div class="row g-2">
            <div
              v-for="(value, valueKey) in size"
              :key="valueKey"
              class="col-md-6"
            >
              <label class="form-label">{{
                valueKey.charAt(0).toUpperCase() + valueKey.slice(1)
              }}</label>
              <input
                v-model.number="meta.available_sizes[sizeKey][valueKey]"
                type="number"
                class="form-control"
                @input="emitChanges"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { merge } from "lodash";
import { initChoices } from "@/assets/js/init-choices.js";
import apiMiscList from "@/services/apiMiscList";
import ArgonSelect from "@/components/ArgonSelect.vue";
export default {
  name: "MetaEditor",
  components: { ArgonSelect },
  props: {
    initialMeta: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:meta"],
  data() {
    return {
      colorKeys: ["blue", "purple", "orange"],
      categoryChoices: [],
      meta: {
        items: { meals: 0, snacks: 0, sides: 0 },
        category: [],
        suitable_for: { ar: [], en: [] },
        ribbon_tag: { text: { ar: "", en: "" }, color_key: "" },
        available_sizes: {
          regular: { min: 0, max: 0 },
          upsized: { min: 0, max: 0 },
        },
      },
    };
  },
  created() {
    this.categoryChoices = apiMiscList.planCategories();
    const parsedInitialMeta = JSON.parse(JSON.stringify(this.initialMeta));
    if (parsedInitialMeta.category) {
      if (
        typeof parsedInitialMeta.category === "object" &&
        !Array.isArray(parsedInitialMeta.category)
      ) {
        parsedInitialMeta.category = parsedInitialMeta.category.en;
      }
    }

    if (Array.isArray(parsedInitialMeta.suitable_for)) {
      parsedInitialMeta.suitable_for = {
        en: parsedInitialMeta.suitable_for,
        ar: parsedInitialMeta.suitable_for,
      };
    }

    if (typeof parsedInitialMeta.ribbon_tag?.text === "string") {
      parsedInitialMeta.ribbon_tag.text = {
        en: parsedInitialMeta.ribbon_tag.text,
        ar: parsedInitialMeta.ribbon_tag.text,
      };
    }

    this.meta = merge({}, this.meta, parsedInitialMeta);
  },
  mounted() {
    this.initSuitableFor();
  },
  methods: {
    initSuitableFor() {
      const enId = "suitable-for-input-en";
      const arId = "suitable-for-input-ar";

      const choicesConfig = {
        removeItemButton: true,
        delimiter: ",",
        editItems: true,
        duplicateItemsAllowed: false,
        placeholder: true,
        placeholderValue: "Press enter to add",
      };

      initChoices(enId, choicesConfig);
      initChoices(arId, choicesConfig);

      const enElement = document.getElementById(enId);
      const arElement = document.getElementById(arId);

      enElement._choices.setValue(this.meta.suitable_for.en);
      arElement._choices.setValue(this.meta.suitable_for.ar);

      enElement.addEventListener("change", () => {
        if (enElement._choices) {
          this.meta.suitable_for.en = enElement._choices.getValue(true);
          this.emitChanges();
        }
      });

      arElement.addEventListener("change", () => {
        if (arElement._choices) {
          this.meta.suitable_for.ar = arElement._choices.getValue(true);
          this.emitChanges();
        }
      });
    },
    emitChanges() {
      this.$emit("update:meta", JSON.parse(JSON.stringify(this.meta)));
    },
    updateCategories($event) {
      this.meta.category = $event;
      this.emitChanges();
    },
    updateSuitableFor() {
      this.meta.suitable_for = this.suitableForString
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item !== "");
      this.emitChanges();
    },
  },
};
</script>
