<!-- components/ArgonTranslate.vue -->
<template>
  <div>
    <label class="form-label mt-4"
      >{{ label }}<sup v-if="required">*</sup></label
    >
    <div class="nav nav-tabs mb-2">
      <button
        v-for="lang in availableLanguages"
        :key="lang.code"
        type="button"
        class="nav-link py-1 px-3"
        :class="activeLanguage === lang.code ? 'active' : ''"
        @click="setActiveLanguage(lang.code)"
      >
        {{ lang.name }}
      </button>
    </div>
    <textarea
      v-if="isTextarea"
      :id="`${id}-${activeLanguage}`"
      v-model="localValue[activeLanguage]"
      class="form-control"
      :rows="rows"
      :placeholder="placeholder"
      :dir="direction"
      @input="updateValue"
    ></textarea>
    <input
      v-else
      :id="`${id}-${activeLanguage}`"
      v-model="localValue[activeLanguage]"
      class="form-control"
      type="text"
      :placeholder="placeholder"
      :dir="direction"
      @input="updateValue"
    />
  </div>
</template>

<script>
import CONSTANTS from "@/constants";
export default {
  name: "ArgonTranslate",
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isTextarea: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    placeholder: {
      type: String,
      default: "",
    },
    languages: {
      type: Array,
      default: () => CONSTANTS.LANGUAGES,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      localValue: {},
      activeLanguage: "",
    };
  },
  computed: {
    availableLanguages() {
      return this.languages;
    },
    defaultLanguage() {
      return this.availableLanguages.length > 0
        ? this.availableLanguages[0].code
        : "en";
    },
    direction() {
      return this.availableLanguages.find(
        (lang) => lang.code === this.activeLanguage
      )?.dir;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.setLocalValueFromProp(newValue);
      },
      immediate: true,
      deep: true,
    },
    languages: {
      handler() {
        this.initializeLocalValue();
        if (
          !this.availableLanguages.some(
            (lang) => lang.code === this.activeLanguage
          )
        ) {
          this.activeLanguage = this.defaultLanguage;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.activeLanguage = this.defaultLanguage;

    this.initializeLocalValue();
  },
  methods: {
    initializeLocalValue() {
      const newLocalValue = {};
      this.availableLanguages.forEach((lang) => {
        newLocalValue[lang.code] = "";
      });
      if (this.localValue) {
        Object.keys(this.localValue).forEach((langCode) => {
          if (this.availableLanguages.some((lang) => lang.code === langCode)) {
            newLocalValue[langCode] = this.localValue[langCode];
          }
        });
      }

      this.localValue = newLocalValue;
      this.setLocalValueFromProp(this.modelValue);
    },
    setLocalValueFromProp(newValue) {
      this.availableLanguages.forEach((lang) => {
        this.localValue[lang.code] = "";
      });

      if (newValue && typeof newValue === "object") {
        Object.keys(newValue).forEach((langCode) => {
          if (this.availableLanguages.some((lang) => lang.code === langCode)) {
            this.localValue[langCode] = newValue[langCode] || "";
          }
        });
      }
    },

    setActiveLanguage(langCode) {
      this.activeLanguage = langCode;
    },
    updateValue() {
      this.$emit("update:modelValue", this.localValue);
    },
  },
};
</script>

<style scoped></style>
