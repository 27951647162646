// constants.js
export default {
  ROLES: {
    SUPER_ADMIN: 1,
    ACCOUNT_ADMIN: 2,
    ACCOUNT_MANAGER: 3,
    ACCOUNT_SUPERVISOR: 4,
    NUTRITIONIST: 5,
    SALES_AGENT: 8,
    SALES_MANAGER: 9,
  },
  LANGUAGES: [
    { code: "en", name: "English", dir: "ltr" },
    { code: "ar", name: "Arabic", dir: "rtl" },
    { code: "ru", name: "Russian", dir: "ltr" },
  ],
};
